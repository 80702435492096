var exports = {};
exports = {
  "fr": {
    "name": "France",
    "bbox": [[-4.59235, 41.380007], [9.560016, 51.148506]]
  },
  "us": {
    "name": "United States",
    "bbox": [[-171.791111, 18.91619], [-66.96466, 71.357764]]
  },
  "ru": {
    "name": "Russia",
    "bbox": [[19.66064, 41.151416], [190.10042, 81.2504]]
  },
  "ca": {
    "name": "Canada",
    "bbox": [[-140.99778, 41.675105], [-52.648099, 83.23324]]
  }
};
export default exports;